.button {
  font-size: rem(16);
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: rem(12) rem(24);
  text-transform: uppercase;

  .icon {
    transition: all 0.4s ease;
  }

  &:hover,
  &:focus {
    .icon {
      // transform: scale(1.25);
      // transform-origin: bottom left;
    }
  }

  &--primary {
    background-color: #FFCC00;
    color: #1D1D1D;
    border-radius: 9999px;
    border: 1px solid #FFCC00;

    &:focus,
    &:hover {
      text-decoration: underline;
      color: #1D1D1D;
    }

    .icon {
      fill: currentColor;
    }
  }

  &--link {
    background-color: transparent;
    color: $body-color;
    border-radius: 8px;
    border: 1px solid transparent;
    padding: 0;
    text-decoration: none;
    font-size: rem(18);
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 0;
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: $body-color;
      visibility: hidden;
      transition: all 0.3s ease-in-out;
    }

    &:focus,
    &:hover {
      text-decoration: none;
      color: $body-color;

      .icon {
        transform: scale(1.25);
        transform-origin: bottom left;
      }

      &:before {
        visibility: visible;
        width: 100%;
      }
    }

    .icon {
      fill: $body-color;
      transition: all 0.4s ease;
    }

    &.button--link--active {
      &:before {
        visibility: visible;
        width: 100%;
      }
    }
  }

  &--supplemental {
    background-color: transparent;
    color: $body-color;
    border: 1px solid transparent;
    padding: 0;
    text-decoration: none;
    position: relative;
    font-size: rem(14);
    font-weight: 400;

    &:focus,
    &:hover {
      text-decoration: none;
      color: $body-color;

      span {
        &:before {
          visibility: visible;
          width: 100%;
        }
      }
    }

    .icon {
      fill: $body-color;
      width: rem(18);
      height: rem(18);
    }
  }

  &--filter {
    background-color: transparent;
    color: $body-color;
    border-radius: 8px;
    border: 1px solid transparent;
    padding: 0;
    text-decoration: none;
    font-size: rem(18);
    position: relative;

    span {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        width: 0;
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: $body-color;
        visibility: hidden;
        transition: all 0.3s ease-in-out;
      }
    }

    &:focus,
    &:hover {
      text-decoration: none;
      color: $body-color;

      span {
        &:before {
          visibility: visible;
          width: 100%;
        }
      }
    }

    .icon {
      fill: $body-color;
    }
  }

  &--small {
    padding: rem(4) rem(16);
  }

  &--large {
    font-weight: 600;
    padding: rem(20) rem(16);
    display: flex;

    .icon--before {
      margin-right: rem(16);
    }

    .icon--after {
      margin-left: rem(16);
    }
  }
}

.text-link {
  display: inline-flex;
  align-items: center;
  color: $body-color;
  font-weight: 600;
}
