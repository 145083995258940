.block {
  &__title {
    margin-bottom: rem(40);
    text-align: center;
    @include heading-2();

    &:after {
      content: "";
      display: block;
      width: rem(165);
      height: 2px;
      background-color: #096F1E;
      margin-left: auto;
      margin-right: auto;
      margin-top: 0.5em;
    }
  }

  &__header-inner {
    margin-bottom: rem(24);

    @include media-breakpoint-up(lg) {
      margin-bottom: rem(32);
    }
  }

  &__header {
    .content {
      margin-bottom: rem(-24);
    }
  }
}

