///
// Block stage
///

.block-stage {
  display: flex;
  flex-direction: column;

  .container {
    position: relative;
  }

  &__title {
    margin-bottom: rem(32);
    @include heading-2();

    &:after {
      content: "";
      display: block;
      width: 25%;
      height: 2px;
      background-color: #096F1E;
      margin-left: auto;
      margin-right: auto;
      margin-top: rem(6);
    }
  }

  &__content {
    order: 3;
    background-color: #A9C23F;
    text-align: center;
    padding-top: rem(48);
    padding-bottom: rem(48);
    margin-bottom: rem(32);
    position: relative;

    @include media-breakpoint-up(md) {
      padding-top: rem(64);
      padding-bottom: rem(64);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      height: 38px; /* Match the height of the SVG */
      background: url('../../assets/content/organic-edge.svg') no-repeat center;
      background-size: 100% 110%;
    }

    &:before {
      top: rem(-24);

      @include media-breakpoint-up(md) {
        top: rem(-8);
      }
    }

    &:after {
      bottom: rem(-24);
      transform: rotate(180deg);

      @include media-breakpoint-up(md) {
        bottom: rem(-8);
      }
    }
  }

  &__image {
    order: 1;
    overflow: hidden;
    aspect-ratio: 1 / 1;
    width: 100%;
    position: relative;

    @include media-breakpoint-up(md) {
      aspect-ratio: 16 / 9;
    }

    @include media-breakpoint-up(xl) {
      aspect-ratio: 16 / 6.5;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.decor--tomato {
  background: url('../../assets/content/cherry_tomatoes.png') no-repeat center;
  background-size: 100% 100%;
  display: block;
  position: absolute;
  width: 107.5px;
  height: 119px;
  z-index: 99;
  position: absolute;
  bottom: rem(-85);
  left: 0;

  @include media-breakpoint-up(sm) {
    left: -71.5px;
    width: 143.33333334px;
    height: 158.66666666px;
  }

  @include media-breakpoint-up(md) {
    left: -71.5px;
    width: 143.33333334px;
    height: 158.66666666px;
  }

  @include media-breakpoint-up(lg) {
    left: -8.33333333%;
    width: 215px;
    height: 238px;
    bottom: rem(-119);
  }

}

.decor--avocado {
  background: url('../../assets/content/avocado.png') no-repeat center;
  background-size: 100% 100%;
  display: block;
  position: absolute;
  width: 137px;
  height: 137px;
  z-index: 99;
  position: absolute;
  top: rem(-96);
  right: -16.66666666%;

  @include media-breakpoint-up(sm) {
    right: -183px;
    width: 183px;
    height: 183px;
  }

  @include media-breakpoint-up(md) {
    right: -91.5px;
    width: 183px;
    height: 183px;
  }

  @include media-breakpoint-up(lg) {
    right: -274px;
    width: 274px;
    height: 274px;
  }

  @include media-breakpoint-up(xl) {
    right: -16.66666666%;
    width: 274px;
    height: 274px;
  }
}


//
// Countdown element
//

.block-stage__countdown {
  position: absolute;
  bottom: rem(-4);
  left: 0;
  right: 0;
}

.countdown {
  padding: rem(16) rem(32);
  color: $white;
  text-align: center;
  background-color: green;
  border-radius: rem(4);

  @include media-breakpoint-up(xl) {
    padding: rem(18) rem(24);
  }

  &__heading {
    margin-bottom: rem(8);
    text-transform: uppercase;
    @include heading-4();
  }

  &__counter {
    display: flex;
    align-content: center;
    justify-content: center;

    p {
      position: relative;
      width: 25%;
      margin-bottom: rem(12);

      &:not(:last-child):after {
        position: absolute;
        right: 0;
        bottom: 50%;
        width: rem(1);
        height: rem(32);
        content: '';
        transform: translate(-0%,50%);

        @include media-breakpoint-up(xl) {
          height: rem(40);
        }
      }
    }
  }

  .counter-value {
    display: block;
    font-family: var(--bs-font-sans-serif);
    font-size: rem(40);
    font-weight: 600;
    line-height: 1.125;
    letter-spacing: 0.1em;

    @include media-breakpoint-down(xl) {
      margin-bottom: rem(8);
    }

    @include media-breakpoint-up(xl) {
      font-size: rem(80);
    }
  }

  .counter-label {
    display: block;
    font-family: var(--bs-font-sans-serif);
    font-size: rem(14);
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0.1em;

    @include media-breakpoint-up(xl) {
      font-size: rem(16);
    }
  }
}
