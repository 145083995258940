.modal-header {
  display: flex;
  justify-content: flex-end;
  border-bottom: 0;
}

.modal-body {
  padding: 0 !important;
}

.modal-button-close {
  border: 1px solid transparent;
  background-color: transparent;
  padding: rem(12) rem(12);
  display: flex;
  align-items: center;
  @include button--small();

  .icon {
    width: rem(20);
    height: rem(20);
    margin-right: rem(8);
  }
}

.modal-body {
  padding-top: rem(24);
  padding-bottom: rem(24);

  @include media-breakpoint-up(lg) {
    padding-top: rem(24);
    padding-bottom: rem(24);
  }
}
