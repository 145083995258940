@mixin heading-1() {
  font-family: var(--bs-font-sans-serif);
  font-weight: 600;
  // font-size: rem(42);
  font-size: rem(34);
  line-height: 1.3;
  letter-spacing: -0.025em;

  @include media-breakpoint-up(lg) {
    font-size: rem(54);
  }

  @include media-breakpoint-up(xxl) {
    font-size: rem(56);
  }
}


@mixin subtitle() {
  font-family: var(--bs-font-sans-serif);
  font-weight: 400;
  font-size: rem(24);
  line-height: 1.3;
  letter-spacing: 1.5em;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

@mixin heading-2() {
  font-family: var(--bs-font-sans-serif);
  font-weight: 600;
  font-size: rem(24);
  line-height: 1.3;
  letter-spacing: 0.015em;
  text-transform: uppercase;

  @include media-breakpoint-up(lg) {
    font-size: rem(32);
  }
}

@mixin heading-2--regular() {
  font-family: var(--bs-font-sans-serif);
  font-weight: 600;
  font-size: rem(24);
  line-height: 1.3;
  letter-spacing: 0.015em;

  @include media-breakpoint-up(lg) {
    font-size: rem(32);
  }
}


@mixin heading-3() {
  font-family: var(--bs-font-sans-serif);
  font-weight: 600;
  font-size: rem(21);
  line-height: 1.3;
  letter-spacing: 0.015em;

  @include media-breakpoint-up(lg) {
    font-size: rem(28);
  }
}

@mixin heading-4() {
  font-family: var(--bs-font-sans-serif);
  font-weight: 600;
  font-size: rem(18);
  line-height: 1.2;
  letter-spacing: 0.015em;

  @include media-breakpoint-up(lg) {
    font-size: rem(21);
  }
}

@mixin kicker() {
  font-family: var(--bs-font-sans-serif);
  font-weight: 600;
  font-size: rem(16);
  line-height: 1.3;
  letter-spacing: -0.01em;

  @include media-breakpoint-up(lg) {
    font-size: rem(18);
  }
}

@mixin lead() {
  font-family: var(--bs-font-sans-serif);
  font-weight: 400;
  font-size: rem(24);
  line-height: 1.3;
  letter-spacing: -0.01em;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

@mixin paragraph() {
  font-family: var(--bs-font-sans-serif);
  font-weight: 400;
  font-size: rem(16);
  line-height: 1.5;
  letter-spacing: 0.015em;

  @include media-breakpoint-up(lg) {
    font-size: rem(18);
  }
}

@mixin paragraph--strong() {
  font-family: var(--bs-font-sans-serif);
  font-weight: 600;
  font-size: rem(16);
  line-height: 1.5;
  letter-spacing: 0.015em;

  @include media-breakpoint-up(lg) {
    font-size: rem(18);
  }
}

@mixin paragraph--small() {
  font-family: var(--bs-font-sans-serif);
  font-weight: 400;
  font-size: rem(14);
  line-height: 1.5;
  letter-spacing: 0.01em;
}

@mixin paragraph--highlight() {
  font-family: var(--bs-font-sans-serif);
  font-weight: 600;
  font-size: rem(21);
  line-height: 1.5;
  letter-spacing: 0.015em;

  @include media-breakpoint-up(lg) {
    font-size: rem(21);
  }
}

@mixin teaser-paragraph() {
  font-family: var(--bs-font-sans-serif);
  font-weight: 400;
  font-size: rem(16);
  line-height: 1.5;
  letter-spacing: 0.01em;
}

@mixin label() {
  font-family: var(--bs-font-sans-serif);
  font-weight: 600;
  font-size: rem(16);
  line-height: 1.5;
  letter-spacing: 0.025em;
}

@mixin label--small() {
  font-family: var(--bs-font-sans-serif);
  font-weight: 600;
  font-size: rem(14);
  line-height: 1.5;
  letter-spacing: -0.01em;
}


@mixin tag-text--small() {
  font-family: var(--bs-font-sans-serif);
  font-weight: 400;
  font-size: rem(16);
  line-height: 1.5;
  letter-spacing: -0.01em;
}

@mixin caption() {
  font-family: var(--bs-font-sans-serif);
  font-weight: 400;
  font-size: rem(14);
  line-height: 1.5;
  letter-spacing: -0.01em;
}


@mixin tagline() {
  font-family: var(--bs-font-sans-serif);
  font-weight: 600;
  font-size: rem(18);
  line-height: 1.5;
  letter-spacing: -0.01em;
}

@mixin heading-2--freestyle() {
  font-family: var(--bs-font-sans-serif);
  font-weight: 600;
  font-size: rem(34);
  line-height: 1.2;
  letter-spacing: -0.025em;
}

@mixin breadcrumb() {
  font-family: var(--bs-font-sans-serif);
  font-weight: 400;
  font-size: rem(14);
  line-height: 1.5;
  letter-spacing: 0.01em;
}

@mixin button() {
  font-family: var(--bs-font-sans-serif);
  font-weight: 400;
  font-size: rem(18);
  line-height: 1.5;
  letter-spacing: 0.01em;
}

@mixin button--small() {
  font-family: var(--bs-font-sans-serif);
  font-weight: 400;
  font-size: rem(14);
  line-height: 1.5;
  letter-spacing: 0.01em;
}

@mixin sitemap-heading() {
  font-family: var(--bs-font-sans-serif);
  font-weight: 600;
  font-size: rem(16);
  line-height: 1.5;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}

@mixin sitemap-item() {
  font-family: var(--bs-font-sans-serif);
  font-weight: 400;
  font-size: rem(16);
  line-height: 1.5;
  letter-spacing: 0.01em;
}

///
/// Content
///

@mixin content() {
  margin-bottom: rem(40);

  h2 {
    margin-bottom: rem(24);
    @include heading-2();
  }

  h3 {
    margin-bottom: rem(24);
    margin-top: rem(32);
    @include heading-3();
  }

  p {
    @include paragraph();
  }

  .lead {
    @include lead();
  }

  .tagline {
    margin-bottom: rem(4);
    @include tagline();
  }

  ul, ol {
    margin-bottom: rem(32);
    @include paragraph();
  }
}
