///
// Header
///

.page-header {
  background-color: $white;
  padding-top: rem(8);
  padding-bottom: rem(8);
  border-bottom: 1px solid #E4E4E4;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  @include media-breakpoint-up(md) {
    padding-top: rem(16);
    padding-bottom: rem(16);
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    @include media-breakpoint-up(lg) {
      justify-content: center;
    }

  }
}

.page-header-brand-single-market {
  @include media-breakpoint-up(lg) {
    position: absolute;
    left: 1.5rem;
  }

}

.page-header-brand-market,
.page-header-brand-single-market {
  img {

    @include media-breakpoint-down(md) {
      height: rem(44);
      width: auto;
    }

    @include media-breakpoint-down(sm) {
      height: rem(32);
      width: auto;
    }
  }
}

.page-header-social-menu {
  position: absolute;
  right: 1.5rem;

  @include media-breakpoint-down(lg) {
    display: none;
  }

}

.page-header .social-navigation {

  &__menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: rem(20);
    margin: 0;
    padding: 0;
    list-style: none;
    @include list-unstyled();
  }

  .icon {
    width: 1.25rem;
    height: 1.25rem;
  }
}
