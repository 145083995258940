///
// Columnsets
///

.row {
  @include make-row();

  &--9-0 {
    .column {
      @include make-col-ready();
      @include make-col(12);

      @include media-breakpoint-up(lg) {
        @include make-col(10);
      }

      @include media-breakpoint-up(xl) {
        @include make-col(9);
      }
    }
  }

  &--4-1-7-switch {
    .column {
      @include make-col-ready();
      @include make-col(12);

      &:nth-child(1) {
        order: 2;

        @include media-breakpoint-down(lg) {
          margin-bottom: rem(24);
        }

        @include media-breakpoint-up(lg) {
          @include make-col(10);
          @include make-col-offset(0);
        }

        @include media-breakpoint-up(xl) {
          @include make-col(7);
          @include make-col-offset(1);
        }
      }

      &:nth-child(2) {
        order: 1;
        @include make-col(8);
        @include make-col-offset(2);

        @include media-breakpoint-up(lg) {
          @include make-col(5);
          @include make-col-offset(0);
        }

        @include media-breakpoint-up(xl) {
          @include make-col(4);
          @include make-col-offset(0);
        }
      }
    }
  }

  &--5-1-5 {
    .column {
      @include make-col-ready();
      @include make-col(12);

      &:nth-child(1) {
        @include media-breakpoint-down(lg) {
          margin-bottom: rem(24);
        }

        @include media-breakpoint-up(lg) {
          @include make-col(5.5);
        }
      }

      &:nth-child(2) {
        @include media-breakpoint-up(lg) {
          @include make-col(5.5);
          @include make-col-offset(1);
        }
      }
    }
  }

  &--8-4 {
    .column {
      @include make-col-ready();
      @include make-col(12);

      &:nth-child(1) {
        @include media-breakpoint-down(lg) {
          margin-bottom: rem(24);
        }

        @include media-breakpoint-up(lg) {
          @include make-col(8);
        }
      }

      &:nth-child(2) {
        @include media-breakpoint-up(lg) {
          @include make-col(4);
        }
      }
    }
  }


  &--5-1-6 {
    .column {
      @include make-col-ready();
      @include make-col(12);

      &:nth-child(1) {
        @include media-breakpoint-down(lg) {
          margin-bottom: rem(24);
        }

        @include media-breakpoint-up(lg) {
          @include make-col(10);
        }

        @include media-breakpoint-up(xxl) {
          @include make-col(6);
        }
      }

      &:nth-child(2) {
        @include media-breakpoint-up(lg) {
          @include make-col(10);
        }

        @include media-breakpoint-up(xxl) {
          @include make-col(5);
          @include make-col-offset(1);
        }
      }
    }
  }

  &--5-1-6-switch {
    .column {
      @include make-col-ready();
      @include make-col(12);

      &:nth-child(1) {
        @include media-breakpoint-down(lg) {
          margin-bottom: rem(24);
        }

        @include media-breakpoint-up(lg) {
          @include make-col(10);
        }

        @include media-breakpoint-up(xl) {
          order: 2;
          @include make-col(6);
          @include make-col-offset(1);
        }
      }

      &:nth-child(2) {
        @include media-breakpoint-up(lg) {
          @include make-col(10);
        }

        @include media-breakpoint-up(xl) {
          order: 1;
          @include make-col(5);
        }
      }
    }
  }
}
