@import "variables";

$dropdown-padding-x: 0;
$dropdown-padding-y: 0;
$dropdown-item-padding-y: 8px;
$dropdown-item-padding-x: 16px;
$dropdown-min-width: 248px;

$grid-gutter-width: 3rem;

$primary: #2D2926;

$link-color: $body-color;

// Import bootstrap core

@import "~bootstrap";

// Overwrite bootstrap css variables

:root {
  --bs-font-sans-serif: "Montserrat", sans-serif;
  --bs-body-color: #1D1D1D;
  --bs-border-color: #1D1D1D;
  --bs-grid-gutter-width: 3rem;
}

// Import splide slider styles

@import "~@splidejs/splide/dist/css/splide-core.min";
// @import "~@splidejs/splide/dist/css/splide.min";

// Overwrite boostrap variables

:root {
  --bs-font-sans-serif: "Montserrat", sans-serif;
  --bs-body-color: #1D1D1D;
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
}

:root {
  --select-border: $body-color;
  --select-focus: $body-color;
  --select-arrow: var(--select-border);
}

@import "abstracts/functions/functions";
@import "abstracts/mixins/mixins";

@import "base/fonts";
@import "base/icons";
@import "base/typography";
@import "base/columns";

@import "elements/buttons";

@import "components/header";
@import "components/footer";
@import "components/block";
@import "components/content";
@import "components/modal";
@import "components/stage";
@import "components/slider/splide-slider";


body {
  max-width: 100%;
  overflow-x: hidden;
}

.block-text-image {
  padding-top: rem(32);
  padding-bottom: rem(32);

  @include media-breakpoint-up(lg) {
    padding-top: rem(48);
    padding-bottom: rem(48);
  }

  .image {
    margin-bottom: rem(32);
  }
}

.image {
  border-radius: rem(4);
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  &__caption {
    @include paragraph--small();
    margin-top: rem(8);
  }
}

.block-gallery {
  text-align: center;
  padding-top: rem(24);
  padding-bottom: rem(24);

  @include media-breakpoint-up(lg) {
    padding-top: rem(48);
    padding-bottom: rem(48);
  }
}


.block-merchant-overview {
  padding-top: rem(48);
  padding-bottom: rem(64);

  @include media-breakpoint-up(md) {
    padding-top: rem(48);
    padding-bottom: rem(80);
  }

  .content {
    text-align: center;
  }
}

.merchant-list-item {
  border-bottom: 2px solid #8E8E8E;
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  padding: rem(12);
  height: 100%;

  &__number {
    border: 2px solid #8E8E8E;
    width: rem(40);
    height: rem(40);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    text-align: center;
    border-radius: 50%;
    margin-top: rem(4);
    @include paragraph--strong();
  }

  &__name,
  &__category {
    display: block;
  }

  &__name {
    @include paragraph--strong();
  }

  &__category {
    @include paragraph--small();
  }
}

.merchant-list {
  margin-bottom: rem(32);
}

.block-teaser-highlight {
  position: relative;
  background-color: #FFCC00;
  padding-top: rem(48);
  padding-bottom: rem(48);

  @include media-breakpoint-up(md) {
    padding-top: rem(64);
    padding-bottom: rem(64);
  }

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    height: 38px; /* Match the height of the SVG */
    background: url('../../assets/content/organic-edge--yellow.svg') no-repeat center;
    background-size: 100% 110%;
  }

  &:before {
    top: rem(-24);

    @include media-breakpoint-up(md) {
      top: rem(-8);
    }
  }

  &:after {
    bottom: rem(-24);
    transform: rotate(180deg);

    @include media-breakpoint-up(md) {
      bottom: rem(-8);
    }
  }

  .button--primary {
    background-color: #D14323;
    color: #fff;

    &:hover,
    &:focus {
      background-color: darken(#D14323, 5%);
    }
  }
}

.teaser-highlight {
  &__title {
    margin-bottom: rem(16);
    font-family: var(--bs-font-sans-serif);
    font-weight: 600;
    font-size: rem(28);
    line-height: 1.3;
    letter-spacing: 0.015em;

    @include media-breakpoint-up(lg) {
      font-size: rem(28);
    }
  }

  &__text {
    p {
      margin-bottom: rem(32);
      @include paragraph();
    }
  }
}

.merchant-overview-image {
  margin-top: rem(24);
  margin-bottom: rem(24);

  @include media-breakpoint-up(lg) {
    margin-top: rem(48);
    margin-bottom: rem(24);
  }
}

.block-teaser-large {
  padding-top: rem(48);
  padding-bottom: rem(48);

  @include media-breakpoint-up(lg) {
    padding-top: rem(90);
    padding-bottom: rem(90);
  }

  .image {
    position: relative;
    z-index: 2;
    @include media-breakpoint-down(xl) {
      margin-bottom: rem(24);
    }
  }

}

.block-teaser-highlight {
  .container {
    position: relative;
  }

  .image {
    position: relative;
    z-index: 2;
    @include media-breakpoint-down(xl) {
      margin-bottom: rem(24);
    }
  }
}

.decor--orange {
  background: url('../../assets/content/orange.svg') no-repeat center;
  background-size: 100% 100%;
  display: block;
  position: absolute;
  width: 209px;
  height: 209px;
  z-index: 1;
  position: absolute;
  bottom: rem(-104);
  right: rem(-70);

  @include media-breakpoint-up(xl) {
    width: 418px;
    height: 418px;
    bottom: rem(-230);
    right: rem(-220);
  }
}


.block-content {
  padding-top: rem(64);
  padding-bottom: rem(32);

  @include media-breakpoint-up(lg) {
    padding-top: rem(80);
    padding-bottom: rem(48);
  }

  .content {
    h1 {
      margin-bottom: rem(32);
      @include heading-1();
    }

    h2 {
      margin-top: rem(32);
      margin-bottom: rem(32);
      @include heading-2--regular();
    }

    h3 {
      margin-top: rem(8);
      margin-bottom: rem(24);
      @include heading-3();
    }

    h4 {
      margin-bottom: 1em;
      @include heading-4();
    }

    ul,
    ol {
      margin-top: 3em;
      margin-bottom: 3em;
      @include paragraph();
    }

    li {
      margin-bottom: 0.5em;
    }

    p {
      margin-bottom: 1.5em;
      @include paragraph();
    }

    .highlight {
      margin-bottom: 1.5em;
      @include paragraph--highlight();
    }

    .lead {
      margin-bottom: 1.5em;
      @include lead();
    }
  }
}

.content-block {
  margin-bottom: rem(32);

@include media-breakpoint-up(lg) {
    margin-bottom: rem(56);
  }
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

.page-footer {
  margin-top: 12.5rem;
}
