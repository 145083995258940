.splide__slide {
  max-width: 100%;
}

///
// Splide gallery
///

.splide-gallery {
  position: relative;
  margin-top: rem(48);

  .gallery-item {
    background: transparent;
    border: 0;
    display: block;
    padding: 0;
    margin: 0;
    border-radius: rem(4);
    overflow: hidden;
    width: 100%;

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  .splide__arrows {
    .splide__arrow {
      background-color: #A9C23F;
      border: 1px solid #A9C23F;
      margin-top: rem(-32);
      // opacity: 0.8;
      position: absolute;
      top: 50%;
      z-index: 1;
      padding: rem(8);

      @include media-breakpoint-up(md) {
        margin-top: rem(-28);
      }

      .icon {
        height: rem(32);
        width: rem(32);
        fill: #096F1E;

        @include media-breakpoint-up(md) {
          height: rem(40);
          width: rem(40);
        }
      }

      &:hover,
      &:focus {
        opacity: 1;

        .icon {
          fill: $white;
        }
      }

      &--prev {
        left: rem(0);

        @include media-breakpoint-up(md) {
          left: rem(-28);
        }
      }

      &--next {
        right: rem(0);

        @include media-breakpoint-up(md) {
          right: rem(-28);
        }
      }
    }
  }
}


///
// Modal gallery
///

.splide-modal {

  .splide__arrows {
    .splide__arrow {
      background-color: #A9C23F;
      border: 1px solid #A9C23F;
      margin-top: rem(-32);
      // opacity: 0.8;
      position: absolute;
      top: 50%;
      z-index: 1;
      padding: rem(8);

      @include media-breakpoint-up(md) {
        margin-top: rem(-28);
      }

      .icon {
        height: rem(32);
        width: rem(32);
        fill: #096F1E;

        @include media-breakpoint-up(md) {
          height: rem(40);
          width: rem(40);
        }
      }

      &:hover,
      &:focus {
        opacity: 1;

        .icon {
          fill: $white;
        }
      }

      &--prev {
        left: rem(0);

        @include media-breakpoint-up(md) {
          left: rem(-28);
        }
      }

      &--next {
        right: rem(0);

        @include media-breakpoint-up(md) {
          right: rem(-28);
        }
      }
    }
  }

  .gallery-item {
    &__copyright {
      font-family: $font-family-sans-serif;
      font-size: rem(14);
      font-weight: 300;
      letter-spacing: 0.025em;
      line-height: 1.2;
      color: $body-color;
      margin-bottom: 1em;
    }

    &__caption {
      text-align: center;
      @include paragraph();
      margin-top: rem(24);
      margin-bottom: rem(24);
    }
  }

}
