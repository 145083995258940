.icon {
  width: 1.5rem;
  height: 1.5rem;

  &--before {
    margin-right: rem(8);
  }

  &--after {
    margin-left: rem(8);
  }
}
