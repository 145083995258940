///
// Block content
///

.block {
  $self: &;

  &-content {
    #{ $self }__header-inner {
      @include media-breakpoint-up(lg) {
        max-width: 58.33333331%;
      }
    }

    .heading-group {
      margin-bottom: rem(32);
    }
  }
}

.content {
  @include media-breakpoint-down(lg) {
    margin-bottom: 2.5rem;
  }

  h3 {
    margin-bottom: 1em;
    @include heading-3();
  }

  p {
    margin-bottom: 1em;
    @include paragraph();
  }

  ul, ol, li {
    @include paragraph();
  }

  .highlight {
    @include paragraph--highlight();
  }
}
