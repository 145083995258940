///
// Color variables
///

$primary: #1D1D1D;
$body-color: #1D1D1D;

$table-border-color: #1D1D1D;

$border-radius: 0;

$grid-gutter-width: 1.5rem;

$header-height--desktop:    6.25rem;
$header-height--mobile:     6.25rem;

$form-select-padding-y: 0.625rem;
$form-select-indicator-padding: 1.25rem;
$form-select-padding-x: 0.625rem;
$form-select-color: #1D1D1D;
$form-select-focus-border-color: #1D1D1D;

///
// Accordion
///

// scss-docs-start accordion-variables
$accordion-padding-y:                     2rem !default;
$accordion-padding-x:                     0 !default;
$accordion-border-width:                  0;

$table-bg:                                transparent;
// scss-docs-end accordion-variables

