///
// Footer component with elements
//
// * General styling
// * Footer sitemap element
// * Single sitemap block
// * Social media links
// * Footer copyright
// * Service navigation / menu
///

.page-footer {
  background-color: #096F1E;
  color: $white;
  padding-top: rem(24);

  &__top {
    padding-top: rem(24);
    padding-bottom: rem(24);

    @include media-breakpoint-up(lg) {
      padding-top: rem(48);
      padding-bottom: rem(48);
    }
  }

  &__bottom {
    &__inner {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid rgba(255, 255, 255, 0.2);
      padding-top: rem(18);
      padding-bottom: rem(18);

      @include media-breakpoint-down(lg) {
        align-items: center;
        flex-direction: column;
      }
    }
  }

  .logo {
    display: block;
    margin-bottom: rem(24);

    @include media-breakpoint-down(lg) {
      margin-bottom: rem(40);
    }
  }

  .logo svg {
    display: block;
  }
}

// Footer sitemap element

.footer-sitemap {
  padding-bottom: rem(32);

  .container {
    display: flex;
    justify-content: space-between;
  }
}

// Single sitemap block

.sitemap-block {
  @include media-breakpoint-down(lg) {
    margin-bottom: rem(24);
  }

  &__heading {
    margin-bottom: rem(24);
    @include sitemap-heading();
  }

  &__menu {
    @include list-unstyled();
  }

  &__menu-item,
  &__menu .menu-item {
    margin-bottom: rem(16);
    @include sitemap-item();
  }

  &__menu-item .menu-link {
    color: rgba(255, 255, 255, 0.8);
    text-decoration: none;

    &:focus,
    &:hover {
      color: rgba(255, 255, 255, 1);
      text-decoration: underline;
    }
  }
}

/// Social media links

.page-footer {
  .social-navigation {
    $self: &;
    padding-bottom: rem(32);

    @include media-breakpoint-down(lg) {
      display: flex;
      // justify-content: center;
    }

    @include media-breakpoint-up(lg) {
      padding-bottom: rem(56);
    }

    &__menu {
      display: flex;
      margin-left: rem(-12);
      margin-right: rem(-12);
      @include list-unstyled();
    }

    &__menu-link {
      .icon {
        fill: $body-color;
        height: rem(32);
        width: rem(32);
      }

      &:hover,
      &:focus {
        .icon {
          fill: $body-color;
        }
      }
    }
  }
}

// Footer copyright

.footer-copyright {
  @include media-breakpoint-down(lg) {
    margin-bottom: rem(24);
    text-align: center;
  }

  p {
    margin: 0;
    @include paragraph--small();
  }
}

// Footer service

.footer-service {
  padding-bottom: rem(48);

  .container {
    @include media-breakpoint-down(lg) {
      display: flex;
      flex-direction: column-reverse;
    }

    @include media-breakpoint-up(lg) {
      display: flex;
      justify-content: space-between;
    }
  }
}

// Footer service navigation

.footer-service-navigation {
  @include media-breakpoint-down(lg) {
    margin-bottom: rem(24);
  }

  &__menu {
    margin-left: rem(-16);
    margin-right: rem(-16);
    @include list-unstyled();
    @include paragraph--small();

    @include media-breakpoint-down(lg) {
      text-align: center;
    }

    @include media-breakpoint-up(lg) {
      display: flex;
      text-align: right;
    }
  }

  &__menu-item,
  &__menu .menu-item {
    padding-left: rem(16);
    padding-right: rem(16);

    @include media-breakpoint-down(lg) {
      margin-bottom: rem(24);
    }
  }

  &__menu-link,
  &__menu .nav-link {
    color: $body-color;
    text-decoration: none;

    &:hover,
    &:focus {
      color: $body-color;
      text-decoration: underline;
    }
  }
}

// Footer navigation

.footer-navigation {

}

.footer-menu {
  @include list-unstyled();
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  gap: rem(24);

  &__link {
    color: $white;
    text-decoration: none;
    @include paragraph--small();

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

.page-footer-social-menu {
  @include media-breakpoint-down(lg) {
    margin-bottom: rem(24);
  }

  .social-navigation {

    &__menu {
      padding: 0;
      margin: 0;
      list-style-type: none;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: rem(16);
      @include list-unstyled();
    }

    a {
      color: #fff;

      .icon {
        fill: currentColor;
        height: rem(32);
        width: rem(32);
      }
    }
  }
}
